<template>
  <div class="menu-item menu-search">
    <UTooltip text="Open Search" :shortcuts="searchTooltipShortcut" class="flex">
      <a class="menu-item-link text-white flex items-center" href="/search" @click.prevent="isOpen = true" role="button">
        <UIcon name="i-heroicons-magnifying-glass-16-solid" class="w-5 h-5" />
      </a>
    </UTooltip>

    <UModal v-model="isOpen" :ui="ui.modal">
      <LazyUCommandPalette :ui="ui.command" :auto-select="false" :auto-clear="false" :nullable="true" :groups="groups" @update:model-value="onSelect" placeholder="Search anything about Kudosity..." :empty-state="emptyState" ref="commandPalletteRef" :debounce="500"> </LazyUCommandPalette>
    </UModal>
  </div>
</template>

<script setup lang="ts">
  const config = useRuntimeConfig().public;
  const { search } = useAlgoliaSearch(config.ALGOLIA_INDEX);
  const isOpen = ref(false);

  const router = useRouter();

  const query = ref("");
  const commandPalletteRef = ref();

  const loading = ref(false);

  defineShortcuts({
    meta_k: {
      usingInput: true,
      whenever: [() => !isOpen.value],
      handler: () => {
        isOpen.value = !isOpen.value;
        setTimeout(() => {
          commandPalletteRef.value?.comboboxApi?.inputRef?.focus();
        }, 500);
      },
    },

    escape: {
      usingInput: true,
      whenever: [isOpen],
      handler: () => {
        isOpen.value = false;
      },
    },

    "meta_/": {
      usingInput: true,
      whenever: [isOpen],
      handler: () => {
        navigateTo("https://help.kudosity.com/s/submit-ticket", { external: true });
      },
    },
  });

  const onSearchItemClicked = (item: { click?: () => void; to?: string|URL; href?: string|URL; position?: number; url?: string; queryID?: string; id?: string; }, position: number) => {
    if (item.to && window) {
      if (window?.aa) {
        window.aa("clickedObjectIDsAfterSearch", {
          index: config.ALGOLIA_INDEX,
          eventName: "Page Clicked",
          queryID: item.queryID,
          objectIDs: [item.id],
          positions: [position + 1],
        });
      }

      window.location.assign(item.to);
    }
  };

  const onSelect = (option: { click: () => void; to: string | URL; href: string | URL; position: number }) => {
    isOpen.value = false;
    if (option?.click) {
      option.click();
    } else if (option?.to) {
      onSearchItemClicked(option, option.position);
    } else if (option?.href) {
      window.open(option.href, "_blank");
    }
  };

  const getOS = () => {
    if(!import.meta.client) return null;

    const userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "macOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (!os && /Linux/.test(platform)) {
      os = "Linux";
    }

    return os;
  };

  const metaKey = computed(() => {
    const os = getOS();
    if (os === "macOS") {
      return "⌘";
    } else if (os === "Windows") {
      return "Ctrl";
    } else {
      return null;
    }
  });

  const getShortcut = (key: string) => {
    if (metaKey.value) {
      return [metaKey.value, key];
    } else {
      return null;
    }
  };

  watchEffect(() => {
    if (isOpen.value) {
      setTimeout(() => {
        if(commandPalletteRef.value?.comboboxApi) commandPalletteRef.value?.comboboxApi?.inputRef?.focus();
      }, 500);
    }
  });

  const groups = [
    {
      key: "links",
      label: "Quick links",
      commands: [
        {
          id: "start-trial",
          icon: "i-heroicons-rocket-launch",
          label: "Start a trial",
          to: "/trial",
        },
        {
          id: "pricing",
          icon: "i-heroicons-ticket",
          label: "Pricing",
          to: "/pricing",
        },
        {
          id: "careers",
          icon: "i-heroicons-briefcase",
          label: "Careers",
          to: "/careers",
          suffix: "– we're hiring!",
        },
        {
          id: "contact-us",
          icon: "i-heroicons-chat-bubble-left-ellipsis",
          label: "Contact Us",
          to: "/contact-us",
        },
        {
          id: "submit-ticket",
          icon: "i-heroicons-question-mark-circle",
          label: "Submit a ticket",
          href: "https://help.kudosity.com/s/submit-ticket",
          shortcuts: getShortcut("?"),
        },
      ],
    },
    {
      key: "pages",
      label: (q: string) => q && `Pages matching "${q}"...`,
      search: async (q: string) => {
        if(q.trim().length == 0) return [];

        loading.value = true;
        query.value = q;
        const result = await search({ query: q, requestOptions: { hitsPerPage: 10 } });
        
        loading.value = false;

        const results = (result?.hits || [])
          .map((hit: any, key: number) => {
            let icon = "i-heroicons-document-text";

            if (hit?.breadcrumbs?.includes("News")) icon = "i-heroicons-newspaper";
            if (hit?.breadcrumbs?.includes("Customer Stories")) icon = "i-heroicons-users";
            if (hit?.breadcrumbs?.includes("Articles")) icon = "i-heroicons-chat-bubble-left-right";
            if (hit?.breadcrumbs?.includes("Legal")) icon = "i-heroicons-globe-alt";
            if (hit?.breadcrumbs?.includes("Guides")) icon = "i-heroicons-academic-cap";
            if (hit?.pathname?.includes("/tag/")) return null;

            if (hit?.breadcrumbs?.includes(hit.title)) hit?.breadcrumbs?.pop();
            const suffix = hit?.breadcrumbs?.slice(0, 2).join(" > ").trim();

            return {
              id: hit.objectID,
              position: key,
              icon: icon,
              label: hit.title,
              inactive: "Inactive",
              to: hit.url,
              queryID: result.queryID,
              suffix: suffix === hit.title ? "" : suffix,
            };
          })
          .filter(Boolean);

        results.push({
          id: "more",
          icon: "i-heroicons-magnifying-glass-circle",
          label: "View more",
          inactive: "Inactive",
          to: "/search?q=" + q,
          suffix: "– See all results",
          queryID: result?.queryID,
          position: results.length,
        });
        return results;
      },
    },
  ];

  const ui = ref({
    modal: {
      wrapper: "z-[5000] fixed",
      width: "w-full sm:max-w-2xl",
    },
    command: {
      input: {
        base: "w-full h-14 px-4 placeholder-gray-400 dark:placeholder-gray-500 bg-transparent border-0 text-gray-900 dark:text-white focus:ring-0 focus:outline-none",
      },
      group: {
        label: "px-2 my-2 text-xs font-semibold text-gray-500 dark:text-gray-400",
        command: {
          base: "flex justify-between select-none cursor-default items-center rounded-md px-2 py-2 gap-2 relative w-full",
          active: "bg-gray-200 dark:bg-gray-700/50 text-gray-900 dark:text-white",
          container: "flex items-center gap-3 min-w-0 w-full",
          label: "flex items-center gap-1.5 min-w-0 w-full",
          icon: {
            base: "flex-shrink-0 w-5 h-5",
            active: "text-gray-900 dark:text-white",
            inactive: "text-gray-400 dark:text-gray-500",
          },
          avatar: {
            size: "2xs",
          },
        },
      },
    },
  });

  const searchTooltipShortcut = computed(() => {  
    return getShortcut('K');
  });

  const queryLabael = computed(() => {
    if (query.value.length === 0) return "Search anything about Kudosity...";
    return loading.value ? `Searching for '${query.value}'` : `We couldn't find any items with that term. Please try again.`;
  });

  const emptyState = computed(() => {
    return { icon: "i-heroicons-magnifying-glass-20-solid", label: `We couldn't find any items.`, queryLabel: queryLabael.value };
  });
</script>
